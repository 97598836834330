
.btn-own_blue {
    color: white !important;
    background-color: #43838f  !important;
}
.btn-own_blue:hover {
    color: rgb(57, 57, 57) !important;
    background-color: #ffffff  !important;
    border-color: #43838f  !important;
}
.avatar {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 80%;
    margin: 8px auto 0;
}

.text-own_blue {
    color: #43838f;
}

a {
    color: #43838f;
}

.very-small {
    font-size: 0.5em;
}